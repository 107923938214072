import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAnnualSelfAssessment } from '../../store/actions/dataAction';

const getCurrentYear = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1

    // Determine the quarter based on the current month
    const currentQuarter = Math.ceil(currentMonth / 3);
    // If it's the first quarter, subtract 1 from the current year
    const year = currentQuarter === 1 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

    return year;
}


const AnnualSelfAssessmentView = ({ getAnnualSelfAssessment, match }) => {

    const [formValues, setFormValues] = useState([]);
    const [details, setDetails] = useState({});
    const [isCreated, setIsCreated] = useState(true);

    const [loader, setLoader] = useState(false);
    const year = getCurrentYear()

    useEffect(() => {
        getData();
    }, []);

    const getData = (assessmentYear = year) => {

        setLoader('init')
        const dataReq = {
            year: assessmentYear, emp_code: match.params.emp_code
        }
        getAnnualSelfAssessment(dataReq).then(data => {
            if (data.length <= 0) return setIsCreated(false),setLoader(false)
            const res = data[0]
            if (res.err) {
                alert("Alert", res.err)
                setLoader(false)
            }
            else {
                const form = JSON.parse(res.evaluation)
                const data = res;
                delete res.evaluation;
                setDetails(data)
                setFormValues(form)
                console.log(data)
                setLoader(false)
            }

        })
    }



    return (

        <div className="mt-4 pb-4" style={{ marginLeft: 205, width: '85%' }}>
            <div className="card">
                <div className="card-header text-center">
                    <h5 className="card-title mb-0">Annual Self Assessment - {details.year}</h5>
                </div>
                <div className="card-body p-0">
                    <table className="table mb-0">
                        <tbody>
                            <tr>
                                <th scope="row">Employee Code</th>
                                <td>{details?.emp_code}</td>
                                <th scope="row">Name</th>
                                <td>{details?.emp_name}</td>
                            </tr>
                            <tr>
                                <th scope="row">Department</th>
                                <td>{details?.emp_department}</td>
                                <th scope="row">Designation</th>
                                <td>{details?.emp_designation}</td>
                            </tr>

                            <tr>
                                <th scope="row">Head of Department</th>
                                <td>{details?.emp_departmentHead}</td>
                                <th scope="row">Year</th>
                                <td>{details.year}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {loader === 'init' ? <div className="spinner-border mt-4" role="status">
                <span className="sr-only">Loading...</span>
            </div> :
                !isCreated ? <div class="alert alert-success mt-3" role="alert">
                    User have not yet submitted their <strong>Annual Assessment for {year}</strong>
                </div>
                    : <div className="card">
                        <div className="card-body">
                            <form>
                                {formValues.map((question, index) => (
                                    <div className="mb-3" key={index}>
                                        <label className="form-label" style={{ fontSize: '18px', fontWeight: '600' }}>{index + 1}. {question.ques}</label>
                                        {question.type === 'text' ? (
                                            <div>
                                                <textarea
                                                    readOnly
                                                    className="form-control"
                                                    value={question.value}
                                                    rows={question.value.split('\n').length + 3} // Set a minimum of 2 rows
                                                    // style={{ resize: 'none', overflowY: 'hidden' }} // Disable the textarea's resize handle
                                                />
                                                <hr />
                                            </div>
                                        ) : question.type === 'select' ? (
                                            <div>
                                                {question.options.map((option, optionIndex) => (
                                                    <div className="form-check ml-2 mb-1" key={optionIndex}>
                                                        <input
                                                            readOnly
                                                            className="form-check-input"
                                                            type="radio"
                                                            id={`radio-${index}-${optionIndex}`}
                                                            name={`radio-${index}`}
                                                            value={option}
                                                            checked={question.value === option}
                                                        />
                                                        <label className="form-check-label" htmlFor={`radio-${index}-${optionIndex}`}>
                                                            {option}
                                                        </label>
                                                    </div>
                                                ))}
                                                {question.value === 'Other' && (
                                                    <div className="form-group ml-2 mb-1">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id={`other-input-${index}`}
                                                            value={question.other}
                                                        />
                                                    </div>
                                                )}
                                                <hr />
                                            </div>
                                        ) : null}

                                    </div>
                                ))}
                            </form>

                        </div>
                    </div>}
        </div>

    )
}

const mapStateToProps = state => {
    return {
        // userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAnnualSelfAssessment: (props) => dispatch(getAnnualSelfAssessment(props)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AnnualSelfAssessmentView);
